@import 'styles/variables';
@import 'styles/mixins';
$iconsHoverColor: #fff;
$iconsFocusColor: #b3d0fd;

.footer {
  background-color: $colorBackgroundMainBlue;
  padding: 70px 0 40px;
  color: $colorTextLightGrayMain;
  font-size: 11px;

  &_vip {
    background-color: $colorBgVip;
  }

  a,
  span {
    font-size: 11px;
    margin-bottom: 10px;
    width: fit-content;

    &:hover {
      opacity: 0.8;
    }
  }

  .socialLink {
    &:hover {
      opacity: 1;
    }
  }
}

.customLink {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.footerContainer {
  display: flex;
}

.legalInfo {
  width: 240px;
}

.copyright {
  display: flex;
  flex-direction: column;

  img {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.rightBlock {
  display: flex;
  letter-spacing: 0.4px;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  .column {
    display: flex;
    flex-direction: column;

    b {
      &.marginLink {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }

  .socials {
    display: flex;
    flex-direction: column;
  }
}

.innerColumn {
  display: flex;
  flex-wrap: wrap;

  @include for-desktop {
    flex-wrap: nowrap;
  }
}

.linksColumn {
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @include for-desktop {
    max-width: 155px;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.privacyPolicy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 32px;
}

.copyrightText {
  font-size: $noteFontSize;
  line-height: 20px;
  margin-top: 2px;
  letter-spacing: 0.26px;
  color: $colorTextLightGrayMain;
  opacity: 0.3;
}

.legalEntity {
  font-size: $noteFontSize;
  color: $colorTextLightGrayMain;
  margin-top: 12px;
  opacity: 0.3;
}

.footerText {
  font-size: $xxsFontSize;
  line-height: 18px;
  opacity: 0.5;
  margin-top: 50px;
}

.iconMail {
  margin-top: 3px;
}

.socialsBox {
  max-width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 7px;

  .icon {
    svg {
      width: 20px !important;
      height: 20px !important;
    }

    path {
      transition: all 0.3s ease 0s;
    }

    mask {
      width: 26px;
      height: 26px;
    }

    &:hover {
      path {
        fill: $iconsHoverColor;
      }
    }

    &:active {
      path {
        fill: $iconsFocusColor;
      }
    }
  }

  a {
    margin-bottom: 0;
  }

  a + a {
    margin-left: 20px;
  }
}

@media (--xs-tablet) {
  .rightBlock {
    width: 100%;
  }

  .legalInfo {
    display: flex;
  }

  .legalEntity {
    margin-left: 12px;
  }
}

@media (--tablet), (--mobile), (--xs-tablet) {
  .legalEntity {
    margin-top: 0;
  }
}

@media (--mobile) {
  .rightBlock {
    justify-content: flex-start;

    .column {
      justify-content: flex-start;
    }

    .earnings {
      padding-left: 15px;
      flex-wrap: wrap;
    }
  }
}

@media (--mobile), (--xs-tablet) {
  .legalInfo {
    width: unset;
    margin-bottom: 20px;
  }

  .legalEntity {
    .brToHide {
      display: none;
    }
  }

  .copyright img {
    width: auto;
  }

  .copyright {
    flex-direction: row;
    gap: 20px;
  }

  .footerContainer {
    flex-direction: column;
    padding: 0 20px;
    min-width: 320px;
  }

  .linksColumn {
    margin-right: 48px;
    margin-bottom: 35px;
  }

  .footerText {
    margin: 40px 0 0;
  }

  .rightBlock {
    padding-top: 0;
    width: auto;
    flex-wrap: wrap;
    font-size: 11px;

    .socials {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .footer a {
    font-size: 12px;
  }

  .privacyPolicy {
    height: auto;
    margin: 40px 0 30px;
    flex-direction: row;
    width: 100%;
  }

  .socialsBox {
    margin-bottom: 0;
    max-width: 100%;
    justify-content: center;
  }
}
