html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 16px;
}

.vip {
  background-color: #080410;
}

#zd-button {
  @media (max-width: 765px) {
    width: 66px !important;
    height: 27px !important;
    bottom: 10% !important;
    font-size: 0.7em !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#zd-button div p {
  font-weight: 600;
}

#zd-button-span {
  @media (max-width: 765px) {
    width: 13px !important;
    padding-top: 0 !important;
  }
}

.termsValue {
  display: none;
}

button {
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
}

.container {
  width: 1140px;
  margin: auto;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  width: auto !important;
  padding: 10px 0 !important;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-cartesian-axis-line {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

@media (--mobile), (--xs-tablet), (--tablet) {
  .container {
    width: 95%;
  }
}

.relative {
  position: relative;
}

.swiper-pagination {
  position: absolute;
  bottom: -16px !important; /* Расположение навигационных точек относительно нижнего края слайдера */
  left: 0;
  width: 100%; /* Ширина контейнера навигационных точек */
  display: flex;
  justify-content: center; /* Центрирование точек */
  align-items: center;
  height: 10px;
  z-index: 999 !important;
}

.swiper-pagination-bullet {
  width: 13px !important; /* Ширина одной точки */
  height: 13px !important; /* Высота одной точки */
  background: linear-gradient(#080410, #080410) padding-box, linear-gradient(283.45deg, #ffd37a 13.43%, #ffedd4 25.75%, #ffd37a 38.27%, #ffedd4 69.35%, #ffd37a 92.71%) border-box !important;
  margin: 0 5px; /* Расстояние между точками */
  border-radius: 50%; /* Скругление углов для создания круглых точек */
  cursor: pointer;
  border: 1px solid transparent !important;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(283.45deg, #ffd37a 13.43%, #ffedd4 25.75%, #ffd37a 38.27%, #ffedd4 69.35%, #ffd37a 92.71%) !important;
}

@media screen and (max-width: 765px) {
  .react-calendar__navigation {
    margin-top: 20px !important;
    column-gap: 10px !important;
  }

  .react-calendar__viewContainer {
    margin-top: 20px !important;
  }

  .react-calendar__month-view:nth-child(1) {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
}
