@import 'styles/variables';
@import 'styles/mixins';

.nav-left {
  padding: 44px 36px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $colorBackgroundNavbarBlue;
  z-index: 9;
  width: 252px;

  @include for-less-desktop {
    padding: 0;
    height: 56px;
  }

  @include for-large-desktop {
    width: 280px;
    padding: 44px 46px;
  }

  .logo {
    width: 163px;
    height: 33px;
    margin-bottom: 54px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include for-less-desktop() {
      display: none;
    }

    @include for-mobile() {
      display: block;
      position: fixed;
      margin-bottom: 44px;
      top: 0;
      left: 0;
      width: 100%;
      height: 44px;
      padding: 10px 16px;
      z-index: 9;
      background-color: #fff;

      img {
        width: 121px;
        height: 24px;
      }
    }
  }

  .user {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 40px;

    @include for-less-desktop() {
      display: none;
    }

    &__img {
      width: 32px;
      height: 32px;
      overflow: hidden;
      object-fit: cover;
      image-rendering: crisp-edges;
    }

    &__data {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: fit-content;
      max-width: 150px;
    }

    .copyBtn {
      width: 12px;
      height: 18px;
      object-fit: cover;
      margin-left: 8px;
      position: relative;
      top: 4.5px;
    }

    .successIcon {
      top: 0;
    }

    &__email {
      font-size: 14px;
      font-weight: 300;
      margin-bottom: 2px;
      line-height: 1.43;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__id {
      font-size: 14px;
      color: $colorAccountTextGray;
      line-height: 1.43;
      font-weight: 300;

      &:hover {
        cursor: pointer;
      }

      &__copied {
        margin-left: 8px;
        font-size: 12px;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 2;
    align-items: flex-start;

    @include for-less-desktop() {
      display: none;
    }

    .menu__top {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      @include for-large-desktop {
        row-gap: 40px;
      }
    }

    &:last-child {
      justify-self: flex-end;
    }

    &__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;

      .icon {
        width: 20px;
        height: 20px;
        object-fit: cover;

        svg {
          transition: all 0.3s ease 0s;
          width: 100%;
          height: 100%;
        }
      }

      span {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        transition: color 0.3s ease 0s;
      }

      &:hover,
      &.active {
        span {
          color: $colorBackgroundMainBlue;
        }

        .icon {
          svg {
            fill: $colorBackgroundMainBlue;
          }
        }
      }

      &_logout {
        margin-top: auto;
      }
    }

    .hover-el {
      position: absolute;
      display: inline-block;
      top: -25px;
      left: -14px;
      transition: all 0.5s ease 0s;
      z-index: -1;
      width: 233px;
      height: 84px;

      svg {
        width: 100%;
        height: 100%;
      }

      @include for-large-desktop {
        width: 268px;
      }
    }
  }

  .menu__mobile {
    display: none;

    @include for-less-desktop() {
      display: grid;
      grid-template-columns: 80% 20%;
      width: 100%;
      height: 56px;
      position: relative;
      overflow: hidden;

      &_less {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.5s ease 0s;
        width: 80%;

        &.hide {
          left: -1000%;
        }
      }

      &_more {
        display: flex;
        width: 80%;
        align-items: center;
        position: absolute;
        top: 0;
        left: 1000%;
        transition: all 0.3s ease 0s;

        &.show {
          left: 0;
        }
      }

      &__item {
        height: 56px;
        flex: 1 1 25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 6px;
        border: none;
        background: transparent;
        outline: none;
        padding: 12px 0;

        &_more {
          position: absolute;
          right: 0;
          top: 0;
          width: 20%;
        }

        &.active {
          background-color: #fff;

          span {
            color: $colorBackgroundMainBlue;
          }

          .icon {
            svg {
              fill: $colorBackgroundMainBlue;
            }
          }
        }

        .icon {
          width: 16px;
          height: 16px;
          object-fit: contain;
          transition: all 0.3s ease 0s;
        }

        span {
          font-size: 8px;
          font-weight: 400;
          line-height: 1.25;
          transition: all 0.3s ease 0s;
        }
      }
    }
  }
}
