@import 'styles/variables';
@import 'styles/mixins';

.localeIcon,
.arrowIcon,
.chevronIcon {
  align-self: center;

  svg {
    display: flex;
    align-self: center;
  }
}

.i18n {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 40px;
  border: none;
  gap: 4px;
  border-radius: 12px;

  .arrowIcon {
    transform: rotate(180deg);
  }

  &Group {
    display: none;
    position: absolute;
    top: 100%;
    right: 8px;
    min-width: 112px;
    border-radius: 8px;
    border: 0.5px solid $colorBorderLightGray;
    background-color: $colorWhite;
    z-index: 100;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      padding: 8px 12px;
      border-radius: 8px;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;

      &:hover {
        background-color: $colorHoverGray;
      }
    }
  }

  &:hover {
    @include for-desktop {
      background-color: $colorHoverGray;
    }

    .arrowIcon {
      transform: rotate(0deg);
    }

    .i18nGroup {
      display: block !important;
    }
  }
}

.main {
  padding: 0 12px;
  margin-left: auto;

  @include for-less-tablet {
    width: 100%;
    margin: 24px 0;
    padding: 0;
    color: $colorWhite;
    justify-content: flex-start !important;
  }
}

.i18nPopup {
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
}

.i18nPopupMain {
  visibility: hidden;
  transform: translateX(-100%);
  opacity: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: inherit;
  padding: 77px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $colorWhite;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

  li {
    display: flex;
    width: 100%;
    height: 34px;
    justify-content: space-between;
    padding: 8px 0;
  }

  svg {
    path {
      stroke: $colorWhite;
    }
  }

  &Header {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: transparent;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;

    .chevronIcon {
      transform: rotate(180deg);
    }

    &:hover {
      p {
        color: $colorWhite;
      }
    }
  }
}

.i18nPopupMain.open {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
}

.dark {
  &:hover {
    background-color: transparent;
  }

  li {
    span {
      color: $colorBlack !important;
    }
  }

  .localeIcon {
    path {
      stroke: $colorWhite;
    }
  }

  .arrowIcon {
    path {
      stroke: $colorWhite;
      fill: $colorWhite;
    }
  }

  @include for-less-tablet {
    li {
      span {
        color: $colorWhite !important;
      }
    }
  }
}

@include for-less-tablet {
  .i18n {
    &:hover {
      .i18nGroup {
        display: none !important;
      }
    }
  }

  .chevronIcon {
    margin-left: auto;
  }

  .main {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    svg {
      path {
        stroke: $colorWhite;
      }
    }
  }
}
