@import 'styles/variables';
@import 'styles/mixins';

$violet-color-line: #d099f9;
$yellow-color-line: #fbe695;
$green-color-line: #a6fcdb;
$gray-color-line: #aeaeae;

.sections {
  padding: 52px 24px;
  width: 275px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: $colorBackgroundNavbarBlue;

  .black {
    color: $colorAccountBlack;
    text-transform: lowercase;
  }

  p {
    text-transform: lowercase;
  }

  @include for-large-desktop {
    width: 300px;
  }

  .updates {
    &__title {
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }

    &__text {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 1.43;
      font-weight: 300;
      color: $colorTextGray;
    }

    .coin {
      display: flex;
      align-items: center;
      column-gap: 12px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &__content {
        h3 {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        p {
          font-size: 10px;
          font-weight: 300;
          line-height: 1.4;

          .green {
            color: $colorGreen;
          }

          .red {
            color: red;
          }
        }
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }

  @include for-mobile() {
    display: none;
  }
}

.no_balance {
  img {
    margin: 0 auto 12px auto;
    width: 224px;
    height: 224px;
  }

  p {
    text-align: center;
    font-size: 12px;
    color: $colorAccountTextGray;
    line-height: 1.33;
  }
}

.section {
  &__name {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.33;
    color: $colorAccountTextGray;
    margin-bottom: 8px;

    span {
      text-transform: capitalize;
    }
  }

  &__empty {
    font-size: 11px;
    font-weight: 300;
    line-height: 1.1;
    color: $colorAccountTextGray;
    margin-bottom: 8px;
  }

  &__amount {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 8px;
    color: $colorAccountBlack;
  }

  &__pending {
    font-size: 14px;
    line-height: 1.43;
    font-weight: 400;
    color: $colorTextGray;
    margin-bottom: 16px;

    span {
      text-transform: capitalize;
    }
  }

  &__lines {
    display: grid;
    column-gap: 4px;
    margin-bottom: 16px;

    .line {
      width: 100%;
      height: 6px;
      border-radius: 8px;

      &_violet {
        grid-area: violet;
        background-color: $violet-color-line;
      }

      &_yellow {
        grid-area: yellow;
        background-color: $yellow-color-line;
      }

      &_green {
        grid-area: green;
        background-color: $green-color-line;
      }

      &_gray {
        grid-area: gray;
        background-color: $gray-color-line;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;

    .detail {
      display: flex;
      justify-content: space-between;
      align-self: center;
      width: 100%;

      &__content {
        display: flex;
        column-gap: 4px;
        align-self: center;

        &__line {
          height: 12px;
          width: 6px;
          border-radius: 4px;

          &_violet {
            background-color: $violet-color-line;
          }

          &_yellow {
            background-color: $yellow-color-line;
          }

          &_green {
            background-color: $green-color-line;
          }

          &_gray {
            background-color: $gray-color-line;
          }
        }

        &__coin {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          color: $colorAccountBlack;
        }
      }

      &__amount {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        color: $colorAccountBlack;
      }
    }
  }
}
