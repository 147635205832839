/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/inter-300-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/RobotoMono-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CourierPrime';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/CourierPrime-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/inter-400-latin.eot');
  src:
    url('/fonts/inter-400-latin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-400-latin.woff2') format('woff2'),
    url('/fonts/inter-400-latin.woff') format('woff'),
    url('/fonts/inter-400-latin.ttf') format('truetype'),
    url('/fonts/inter-400-latin.svg#Inter-Regular') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/inter-500-latin.01.eot');
  src:
    url('/fonts/inter-500-latin.01.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-500-latin.01.woff2') format('woff2'),
    url('/fonts/inter-500-latin.01.woff') format('woff'),
    url('/fonts/inter-500-latin.01.ttf') format('truetype'),
    url('/fonts/inter-500-latin.01.svg#intersemi_bold') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/inter-700-latin.eot');
  src:
    url('/fonts/inter-700-latin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/inter-700-latin.woff2') format('woff2'),
    url('/fonts/inter-700-latin.woff') format('woff'),
    url('/fonts/inter-700-latin.ttf') format('truetype'),
    url('/fonts/inter-700-latin.svg#interbold') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url('/fonts/Poppins-ExtraLight.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/Poppins-Light.ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/poppins-400-latin.eot');
  src:
    url('/fonts/Poppins-Regular.ttf') format('truetype'),
    url('/fonts/poppins-400-latin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins-400-latin.woff2') format('woff2'),
    url('/fonts/poppins-400-latin.woff') format('woff'),
    url('/fonts/poppins-400-latin.ttf') format('truetype'),
    url('/fonts/poppins-400-latin.svg#poppinsregular') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/Poppins-Medium.ttf') format(truetype),
    url('/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/poppins-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/poppins-v20-latin-500.woff') format('woff'), /* Modern Browsers */
    url('/fonts/poppins-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/poppins-600-latin.eot');
  src:
    url('/fonts/Poppins-Semibold.ttf') format(truetype),
    url('/fonts/poppins-600-latin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins-600-latin.woff2') format('woff2'),
    url('/fonts/poppins-600-latin.woff') format('woff'),
    url('/fonts/poppins-600-latin.ttf') format('truetype'),
    url('/fonts/poppins-600-latin.svg#poppins_semiboldregular') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/poppins-700-latin.eot');
  src:
    url('/fonts/Poppins-Bold.ttf') format(truetype),
    url('/fonts/poppins-700-latin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins-700-latin.woff2') format('woff2'),
    url('/fonts/poppins-700-latin.woff') format('woff'),
    url('/fonts/poppins-700-latin.ttf') format('truetype'),
    url('/fonts/poppins-700-latin.svg#poppinsbold') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
