.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 39, 39, 0.5);
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  width: 750px;
  background: #fff;
  box-shadow: 0 3px 40px rgba(0, 26, 67, 0.03);
  border-radius: 16px;
}
